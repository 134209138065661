import {DependencyList, useEffect, useLayoutEffect, useState} from "react";
import ErrorResponse from "./response/ErrorResponse";
import {useLocation} from "react-router-dom";

/** Хук useEffect, который может принимать асинхронные функции. Сам он НЕ АСИНХРОНЕН (ваш КЭП ¯\_(ツ)_/¯ )! */
export function useEffectAsync(callback: () => void | (() => void | undefined) | Promise<unknown>, deps: DependencyList = []) {
    useEffect(() => {
        callback();
    }, deps);
}

/** Хук useLayoutEffect, который может принимать асинхронные функции. Сам он НЕ АСИНХРОНЕН (ваш КЭП ¯\_(ツ)_/¯ )! */
export function useLayoutEffectAsync(callback: () => void | (() => void | undefined) | Promise<unknown>, deps: DependencyList = []) {
    useLayoutEffect(() => {
        callback();
    }, deps);
}

/** Просто создает пару loading+error состояний - они нужны в каждом втором компоненте. Экономит одну строчку кода, и никакой rocket science... */
export function useLoadingStatesEx(defaultLoading: boolean = true) {
    const [loading, setLoading] = useState<boolean>(defaultLoading);
    const [error, setError] = useState<ErrorResponse | null>(null);

    return {
        loading,
        setLoading,
        error,
        setError,
    };
}

export function ScrollToTop() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null;
}
import React from "react";
import {H1, TextL} from "@mryaros/mryaros-design-system";

export const PageNewProject = React.memo(function PageNewProject() {
    return (
        <>
            <H1>С чего начать?</H1>
            <ul>
                <li>
                    <TextL>
                        Запросить доступы к&nbsp;
                        <a href="http://gitlab.mryaros.ru/" target="_blank" rel="noreferrer">gitlab</a>,&nbsp;
                        <a href="https://nexus.mryaros.ru/" target="_blank" rel="noreferrer">nexus</a>,&nbsp;
                        <a href="https://mryaros.atlassian.net/wiki/home" target="_blank" rel="noreferrer">документации</a>&nbsp;
                        написав письмо на почту mr.yaros@yandex.ru
                    </TextL>
                </li>
                <li>
                    <TextL>
                        Изучить <a href="https://mryaros.atlassian.net/wiki/spaces/PS/pages/6881281" target="_blank" rel="noreferrer">набор технологий</a>, которые использовались при реализации типового проекта
                    </TextL>
                </li>
                <li>
                    <TextL>
                        Скачать <a href="http://gitlab.mryaros.ru/default_project/default_project.git" target="_blank" rel="noreferrer">серверную</a> и&nbsp;
                        <a href="http://gitlab.mryaros.ru/default_project/default_project_front.git" target="_blank" rel="noreferrer">клиентскую</a> часть типового проекта
                    </TextL>
                </li>
                <li>
                    <TextL>
                        Реализовать свой уникальный алгоритм на основе типового проекта
                    </TextL>
                </li>
                <li>
                    <TextL>
                        Зарегистрировать проект на платформе написав письмо на почту mr.yaros@yandex.ru
                    </TextL>
                </li>
                <li>
                    <TextL>
                        Запустить свой проект
                    </TextL>
                </li>
            </ul>

            <H1>Требования к приложению</H1>
            <ul>
                <li><TextL>В приложении должны поддерживаться запросы, которые обрабатываются платформой. Для этого реализована библиотека, которая хранится в nexus, и типовой проект с использованием этой библиотеки</TextL></li>
                <li><TextL>Язык программирования и прочие технологии не регламентируются</TextL></li>
                <li><TextL>Разработанное web-приложение должно содержать 3 обязательные части: теория с описанием алгоритма, пример взаимодействия с приложением и основную часть с возможностью практического применения реализованного алгоритма</TextL></li>
                <li><TextL>Предлагается использовать компоненты из разработанной дизайн-системы, которая хранится в виде npm-пакета в nexus</TextL></li>
            </ul>
        </>
    );
});
import {Route, Routes} from "react-router-dom";
import {PageAuthorization} from "../page_authorization/PageAuthorization";
import "./page.scss";
import {Button} from "@mryaros/mryaros-design-system";
import {PagePasswordRecovery} from "../page_password_recovery/PagePasswordRecovery";
import {PageRegistration} from "../page_registration/PageRegistration";
import {PageAbout} from "../page_about/PageAbout";
import {PageProjects} from "../page_projects/PageProjects";
import {PageNewProject} from "../page_new_project/PageNewProject";

export function Page() {
    return (
        <div className="page">
            <Routes>
                <Route path="/authorization" element={<PageAuthorization/>}/>
                <Route path="/password_recovery" element={<PagePasswordRecovery/>}/>
                <Route path="/registration" element={<PageRegistration/>}/>
                <Route path="/about" element={<PageAbout/>}/>
                <Route path="/projects" element={<PageProjects/>}/>
                <Route path="/new_project" element={<PageNewProject/>}/>
                <Route path="*" element={<PageAbout/>}/>
            </Routes>
        </div>
    )
}
import {H4, TextM, TypicalFooter} from "@mryaros/mryaros-design-system";
import React from "react";
import {Link} from "react-router-dom";

export const ApplicationFooter = React.memo(function ApplicationFooter() {
    const mainBlocks = (
        <>
            <div>
                <TextM className="margin-no">Разработчик</TextM>
                <H4 className="margin-no">Кузнецов Ярослав Андреевич</H4>
                <TextM className="margin-no">Контактные данные</TextM>
                <H4 className="margin-no">mr.yaros@yandex.ru</H4>
            </div>
            <div>
                <TextM className="margin-no">Научный руководитель</TextM>
                <H4 className="margin-no">Максимов Владимир Петрович</H4>
            </div>
            <div className="flex-col">
                <Link to="/about">О платформе</Link>
                <Link to="/projects" className="link-block">Подключенные проекты</Link>
                <Link to="/new_project" className="link-block">Создать проект</Link>
            </div>
            <div className="flex-col">
                <a href="https://design.mryaros.ru" target="_blank" rel="noreferrer">Дизайн-система</a>
                <a href="https://platform.mryaros.ru" target="_blank" rel="noreferrer">Платформа</a>
            </div>
        </>
    );
    return (
        <TypicalFooter mainBlocks={mainBlocks} className="margin-t-64" />
    );
});
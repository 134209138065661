import {TokenDto} from "./response/TokenDto";
import IResponseBody from "./response/IResponseBody";
import {ConsumerDto} from "./response/ConsumerDto";

export enum RequestEndpoint {
    login = "login",
    logout = "logout",
    getRefreshToken = "refreshtoken",
    users = "users",
    consumers = "consumers",
    getAuthCode = "oauth/code",
    checktoken = "checktoken",
}

type ResponseTypes = {
    [RequestEndpoint.login]: TokenDto,
    [RequestEndpoint.logout]: IResponseBody,
    [RequestEndpoint.getRefreshToken]: TokenDto,
    [RequestEndpoint.users]: IResponseBody,
    [RequestEndpoint.consumers]: ConsumerDto[],
    [RequestEndpoint.getAuthCode]: string,
};

export type RequestInfoTypeMapping = {[key in RequestEndpoint]: IResponseBody} & ResponseTypes
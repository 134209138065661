import React, {useState} from "react";
import {useEffectAsync, useLoadingStatesEx} from "../../core/CustomReactHooks";
import RequestExecutor, {ERequestMethod} from "../../core/RequestExecutor";
import {RequestEndpoint} from "../../core/RequestEndpoint";
import {Button, EButtonSize, ELoaderSize, ENoticeType, H1, H4, IconArrowRight, Loader, Notice, Section, TextS} from "@mryaros/mryaros-design-system";
import {ConsumerDto} from "../../core/response/ConsumerDto";
import MrYarosLogo from "@mryaros/mryaros-design-system/src/lib/core/TypicalHeader/MrYarosLogo.png";

export const PageProjects = React.memo(function PageProjects() {
    const {error, setError, loading, setLoading} = useLoadingStatesEx();
    const [consumers, setConsumers] = useState<ConsumerDto[]>([]);

    useEffectAsync(async () => {
        await RequestExecutor.callNotAuth(RequestEndpoint.consumers, ERequestMethod.GET)
            .then((consumers) => setConsumers(consumers))
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <Loader loaderSize={ELoaderSize.BIG_SIZE} header="Подождите, идет загрузка..." className="margin-t-24" />;
    }

    if (error) {
        return <Notice
            noticeType={ENoticeType.ERROR}
            title="Произошла ошибка"
            text="Во время загрузки списка доступных приложений произошла ошибка. Попробуйте позднее"
            className="width-100 margin-t-24"
        />;
    }

    return (
        <>
            <H1>Библиотека подключенных приложений</H1>
            <div className="flex-row flex-j-around flex-wrap" style={{textAlign: "center"}}>
                {consumers.map((con) =>
                    <Section key={con.id} className="padding-small flex-col-center width-30 margin-b-24" >
                        <img
                            src={con.imageUrl}
                            alt="logo"
                            className="logo pointer"
                            onError={(e) => {
                                // @ts-ignore
                                e.target.src = MrYarosLogo; e.target.onerror = null;
                            }}
                        />
                        <H4 className="margin-t-24">{con.name}</H4>
                        <TextS>{con.description}</TextS>
                        <Button
                            label={<>Перейти <IconArrowRight className="margin-l-16" /></>}
                            className="flex-row-center"
                            onClick={ () => window.location.href = con.url}
                            buttonSize={EButtonSize.MEDIUM}
                        />
                    </Section>
                )}
            </div>
        </>
    );
});
import React from "react";
import './App.scss';
import SessionProvider from "./app/core/context/SessionContext";
import {BrowserRouter} from "react-router-dom";
import {Page} from "./app/pages/page/Page";
import {Background} from "@mryaros/mryaros-design-system";
import {ApplicationHeader} from "./app/components/Header";
import {ApplicationFooter} from "./app/components/ApplicationFooter";
import {ScrollToTop} from "./app/core/CustomReactHooks";

function App() {
  return (
      <BrowserRouter>
          <ScrollToTop />
          <SessionProvider>
            <Background/>
            <div className="app">
                <ApplicationHeader/>
                <Page/>
                <ApplicationFooter/>
            </div>
          </SessionProvider>
      </BrowserRouter>
  )
}

export default App;

import React, {useCallback, useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, EButtonStyle, TypicalHeader} from "@mryaros/mryaros-design-system";
import MrYarosLogo from "@mryaros/mryaros-design-system/src/lib/core/TypicalHeader/MrYarosLogo.png";
import {SessionContext} from "../core/context/SessionContext";

export const ApplicationHeader = React.memo(function ApplicationHeader() {
    const navigate = useNavigate();
    
    const {tokenDto, logout} = useContext(SessionContext);
    
    const onLogoutClick = useCallback(() => {
        logout();
        navigate("/authorization");
    }, [logout, navigate])

    const headerLinks = (<>
        <Link to="/about">О ПЛАТФОРМЕ</Link>
        <Link to="/projects" className="link-block">ПОДКЛЮЧЕННЫЕ ПРОЕКТЫ</Link>
        <Link to="/new_project" className="link-block">СОЗДАТЬ ПРОЕКТ</Link>
    </>);

    const additionalBlock = !tokenDto ? (
        <>
            <Button label="Вход" className="margin-r-24" onClick={() => navigate("/authorization")} />
            <Button label="Регистрация" buttonStyle={EButtonStyle.SECONDARY} onClick={() => navigate("/registration")} />
        </>
    ) : (
        <Button label="выход" buttonStyle={EButtonStyle.SECONDARY} onClick={onLogoutClick} />
    );
            
    return <TypicalHeader logoSrc={MrYarosLogo} headerLinks={headerLinks} additionalBlock={additionalBlock} />
});
import {TokenDto} from "./response/TokenDto";

export function getQueryParam(item: string) {
    const value = window.location.search.match(new RegExp('[?&]' + item + '=([^&]*)(&?)', 'i'));
    return value ? value[1] : value;
}

export function getTokenDtoFromStorage(): TokenDto | null {
    const value = window.localStorage.getItem("Authorization");
    if (value == null) {
        return null;
    }
    return JSON.parse(value) as TokenDto;
}
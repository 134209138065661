import * as React from "react";
import {HTMLAttributes, useCallback, useState} from "react";
import {SessionContextType} from "./@types/SessionContext";
import RequestExecutor, {ERequestMethod, RequestOptions} from "../RequestExecutor";
import {useLayoutEffectAsync, useLoadingStatesEx} from "../CustomReactHooks";
import {TokenDto} from "../response/TokenDto";
import {RequestEndpoint, RequestInfoTypeMapping} from "../RequestEndpoint";
import {useLocation, useNavigate} from "react-router-dom";
import {Buffer} from "buffer";
import ErrorResponse from "../response/ErrorResponse";
import IResponseBody from "../response/IResponseBody";
import {getTokenDtoFromStorage} from "../Utils";

const SessionContextDefault = {
    tokenDto: null,
    callWithTokenCheck: () => Promise.resolve({} as IResponseBody),
    logout: () => {},
    login: (login: string, password: string) => {},
};

// @ts-ignore
export const SessionContext = React.createContext<SessionContextType>(SessionContextDefault);

// todo! NOSESSIONFIX быстрокостыль для страниц без сессии...
const allowWithoutSession = ["registration", "authorization", "password_recovery", "about", "projects", "new_project"];
const notAllowWithSession = ["registration", "password_recovery"]; //authorization сама разрулит

const SessionProvider: React.FC<HTMLAttributes<HTMLElement>> = ( {children}: HTMLAttributes<HTMLElement> ) => {
    const {loading, setLoading} = useLoadingStatesEx();
    const [tokenDto, setTokenDto] = useState<TokenDto | null>(null);

    useLayoutEffectAsync(async () => {
        const tokenDtoStorage = getTokenDtoFromStorage();
        if (tokenDtoStorage) {
            setTokenDto(tokenDtoStorage);
            await callWithTokenCheck(RequestEndpoint.checktoken, ERequestMethod.GET)
                .then(() => setTokenDto(tokenDtoStorage))
                .catch((e) => console.log(e))
        }
        setLoading(false);
    }, []);

    const location = useLocation();
    const navigate = useNavigate();

    // todo! NOSESSIONFIX быстрокостыль для страниц без сессии...
    const isAllowWithoutSession = allowWithoutSession.some((h) => location.pathname.startsWith(`/${h}`));
    const isNotAllowWithSession = notAllowWithSession.some((h) => location.pathname.startsWith(`/${h}`));

    useLayoutEffectAsync(async () => {
        if (loading || (isAllowWithoutSession && !tokenDto)) {
            return;
        }
        if (isNotAllowWithSession && tokenDto) {
            navigate("/");
        }
        if (tokenDto == null) {
            navigate("/authorization");
            await logout();
        }

    }, [location, loading, tokenDto]);

    const login = useCallback(
        async (login: string, password: string, onError: (e: ErrorResponse) => void) => {
            const options = {
                headers: {
                    "Authorization": `Basic ${Buffer.from(`${login}:${password}`).toString("base64")}`
                }
            };
            await RequestExecutor.callNotAuth(RequestEndpoint.login, ERequestMethod.POST, {}, {}, options)
                .then((tokenDto) => {
                    setTokenDto(tokenDto);
                    window.localStorage.setItem("Authorization", JSON.stringify(tokenDto))
                })
                .catch(e => onError(e));
        },
        []
    )

    const logout = useCallback(
        async () => {
            const tokenDtoFromStorage = getTokenDtoFromStorage();
            if (tokenDtoFromStorage) {
                await RequestExecutor.call(RequestEndpoint.logout, ERequestMethod.POST)
                    .finally(() => {
                        window.localStorage.removeItem("Authorization");
                        setTokenDto(null);
                    });
            }
        },
        []
    );

    const refreshToken = useCallback(
        async () => {
            const tokenDtoStorage = tokenDto || getTokenDtoFromStorage();
            await RequestExecutor.callNotAuth(RequestEndpoint.getRefreshToken, ERequestMethod.POST, {refreshToken: tokenDtoStorage?.refreshToken})
                .then((newTokenDto) => {
                    setTokenDto(newTokenDto);
                    window.localStorage.setItem("Authorization", JSON.stringify(newTokenDto));
                })
                .catch(() => logout());
        },
        [tokenDto, logout]
    );
    
    const callWithTokenCheck = useCallback(
        async <K extends RequestEndpoint, RESP extends IResponseBody = RequestInfoTypeMapping[K]> (
            endpoint: K,
            method: ERequestMethod,
            requestBody: object = {},
            queryParam: object = {},
            options: Partial<RequestOptions> = {},
        ): Promise<RESP> => {
            return RequestExecutor.call(endpoint, method, requestBody, queryParam, options)
                .catch(async (error) => {
                    switch (error?.error) {
                        case "ExpiredTokenAuthError":
                            await refreshToken();
                            break;
                        case "MalformedTokenAuthError":
                        case "InvalidTokenAuthError":
                        case "DefaultTokenAuthError":
                            await logout();
                            return;
                        default:
                            return Promise.reject(error);

                    }
                    return RequestExecutor.call(endpoint, method, requestBody, queryParam, options);
                }) as Promise<RESP>;
        }, [refreshToken, logout]
    );

    return (
        <SessionContext.Provider value={{tokenDto, callWithTokenCheck, logout, login}}>
            {children}
        </SessionContext.Provider>
    )
};

export default SessionProvider;
import React from "react";
import {H1, H2, TextL} from "@mryaros/mryaros-design-system";
import {Link} from "react-router-dom";

export const PageAbout = React.memo(function PageAbout() {
    return (
        <>
            <H1>Описание платформы</H1>
            <H2>Цели</H2>
            <ul>
                <li><TextL>Объединить научные приложения, которые разрабатываются студентами в университете, городе, стране, мире;</TextL></li>
                <li><TextL>Сделать науку доступнее для общества, в частности, для бизнеса и предпринимательства;</TextL></li>
                <li><TextL>Создать для студентов комфортную среду для представления разработанных ими проектов;</TextL></li>
                <li><TextL>Информатизация науки</TextL></li>
            </ul>
            <H2>Задачи</H2>
            <ul>
                <li><TextL>Объединить в рамках единой платформы вспомогательные функции большинства web-приложений, такие как авторизация/регистрация, механизм уведомлений и пр.;</TextL></li>
                <li><TextL>Упростить процесс разработки и запуска новых web-приложений путем создания типового проекта;</TextL></li>
                <li><TextL>Унифицировать общий вид подключенных к платформе web-приложений создав единую дизайн-систему;</TextL></li>
                <li><TextL>Предоставить доступ к материалам с описанием используемых технологий, необходимых для разработки web-приложений;</TextL></li>
                <li><TextL>Создание и поддержка библиотеки подключенных web-приложений;</TextL></li>
                <li><TextL>Привлечение новых пользователей и разработчиков</TextL></li>
            </ul>
            <H2>Возможности</H2>
            <ul>
                <li><TextL>Объединение научных сотрудников;</TextL></li>
                <li><TextL>Публикация результатов научной деятельности;</TextL></li>
                <li><TextL>Презентация найденных решений потенциальному пользователю/работодателю;</TextL></li>
                <li><TextL>Усовершенствование процесса обучения новых специалистов</TextL></li>
            </ul>
            <TextL><Link to="/new_project">Создать свой проект</Link></TextL>
        </>
    );
});
import React from "react";
import {Button, EButtonStyle, ENoticeType, H1, Notice} from "@mryaros/mryaros-design-system";
import {useNavigate} from "react-router-dom";

export const PagePasswordRecovery = React.memo(function PagePasswordRecovery() {
    const navigate = useNavigate();

    return (
        <>
            <H1>Восстановление пароля</H1>
            <Notice
                noticeType={ENoticeType.WARNING}
                title="Функция недоступна"
                text="В настоящее время функция восстановления пароля недоступна. Обратитесь к администратору отправив письмо на почту mr.yaros@yandex.ru"
                isFullWidth
            />

            <div className="flex-row width-100 flex-j-around margin-t-48">
                <Button label="Вход" onClick={() => navigate("/authorization")}/>
                <Button label="Регистрация" buttonStyle={EButtonStyle.SECONDARY} onClick={() => navigate("/registration")}/>
            </div>
        </>
    );
});
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, EButtonStyle, ENoticeType, Notice, PasswordField, TextField, Section, Loader, ELoaderSize} from "@mryaros/mryaros-design-system";
import {SessionContext} from "../../core/context/SessionContext";
import ErrorResponse from "../../core/response/ErrorResponse";
import {useLoadingStatesEx} from "../../core/CustomReactHooks";
import {useLocation, useNavigate} from "react-router-dom";
import MrYarosLogo from "@mryaros/mryaros-design-system/src/lib/core/TypicalHeader/MrYarosLogo.png";
import {ERequestMethod} from "../../core/RequestExecutor";
import {RequestEndpoint} from "../../core/RequestEndpoint";

export const PageAuthorization = React.memo(function PageAuthorization() {
    const [userLogin, setUserLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const {error, setError, loading, setLoading} = useLoadingStatesEx(false);
    const {login, tokenDto, callWithTokenCheck} = useContext(SessionContext);
    const navigate = useNavigate();

    const location = useLocation();
    const [codeLoading, setCodeLoading] = useState<boolean>(false);

    useEffect(() => {
        if (tokenDto && location.search) {
            setCodeLoading(true);
            callWithTokenCheck(RequestEndpoint.getAuthCode, ERequestMethod.GET, {}, {}, {searchString: location.search})
                .then((resp) => window.location.href = resp)
                .finally(() => {
                    setCodeLoading(false);
                });
        }
        else if (tokenDto) {
            navigate("/");
        }
    }, [tokenDto, location.search, callWithTokenCheck, navigate]);

    const onLoginError = useCallback((e: ErrorResponse) => {
        setError(e);
    }, [setError]);
    
    const onLoginClick = useCallback(async () => {
        setLoading(true);
        setError(null);
        await login(userLogin, password, onLoginError);
        setLoading(false);
    },[login, onLoginError, password, setError, setLoading, userLogin]);

    if (codeLoading) {
        return <Loader loaderSize={ELoaderSize.BIG_SIZE} header="Подождите, идет загрузка..." className="margin-t-24" />;
    }

    return (
        <Section className="flex-col-center padding-large margin-all-b-24 margin-t-24">
            <div className="flex-col-center margin-all-b-24">
                <img src={MrYarosLogo} alt="logo" />
                <TextField
                    label="Логин"
                    value={userLogin}
                    onChange={setUserLogin}
                />
                <PasswordField
                    label="Пароль"
                    value={password}
                    onForgotPasswordClick={() => navigate("/password_recovery")}
                    onChange={setPassword}
                />
                <div className="flex-row width-100 flex-j-between">
                    <Button label="Войти" loading={loading} onClick={() => onLoginClick()}/>
                    <Button label="Зарегистрироваться" buttonStyle={EButtonStyle.SECONDARY} onClick={() => navigate("/registration")}/>
                </div>
            </div>
            {error && <Notice noticeType={ENoticeType.ERROR} text={error.errorDescription} />}
        </Section>
    );
});
import React, {useCallback, useState} from "react";
import {Button, EButtonSize, EButtonStyle, EButtonWidth, ENoticeType, ETextFieldState, H2, Notice, Section, TextField} from "@mryaros/mryaros-design-system";
import RequestExecutor, {ERequestMethod} from "../../core/RequestExecutor";
import {RequestEndpoint} from "../../core/RequestEndpoint";
import {useLoadingStatesEx} from "../../core/CustomReactHooks";

export const PageRegistration = React.memo(function PageRegistration() {
    const {error, setError, loading, setLoading} = useLoadingStatesEx(false);
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [fullName, setFullName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [address, setAddress] = useState<string>("");

    const isLogin = useCallback(() => !!username, [username]);
    const isPassword = useCallback(() => !!password, [password]);

    const onRegistrationClick = useCallback(async () => {
        if (!isLogin() || !isPassword()) {
            return;
        }
        setLoading(true);
        setError(null);

        await RequestExecutor.callNotAuth(RequestEndpoint.users, ERequestMethod.POST, {username, password, fullName, email, phone, address})
            .catch(e => setError(e))
            .finally(() => setLoading(false));
    }, [isLogin, isPassword, setLoading, setError, username, password, fullName, email, phone, address]);

    return (
        <>
            <Section className="flex-col-center padding-large margin-all-b-24 margin-t-24">
                <H2 className="margin-no">Регистрация</H2>
                <div className="flex-row flex-j-around flex-wrap margin-all-b-32 margin-t-64">
                    <TextField
                        label="Логин"
                        value={username}
                        onChange={setUsername}
                        fieldState={!isLogin() ? ETextFieldState.WARNING : ETextFieldState.DEFAULT}
                        fieldStateText={!isLogin() ? "Поле обязательно для заполнения" : ""}
                    />
                    <TextField
                        label="Пароль"
                        value={password}
                        onChange={setPassword}
                        fieldState={!isPassword() ? ETextFieldState.WARNING : ETextFieldState.DEFAULT}
                        fieldStateText={!isPassword() ? "Поле обязательно для заполнения" : ""}
                    />
                    <TextField
                        label="ФИО"
                        value={fullName}
                        onChange={setFullName}
                    />
                    <TextField
                        label="Email"
                        value={email}
                        onChange={setEmail}
                    />
                    <TextField
                        label="Телефон"
                        value={phone}
                        onChange={setPhone}
                    />
                    <TextField
                        label="Адрес"
                        value={address}
                        onChange={setAddress}
                    />
                </div>
                <Button
                    label="Зарегистрироваться"
                    onClick={onRegistrationClick}
                    buttonStyle={EButtonStyle.SECONDARY}
                    buttonSize={EButtonSize.MEDIUM}
                    buttonWidth={EButtonWidth.FULL_WIDTH}
                    loading={loading}
                />
                {error && <Notice noticeType={ENoticeType.ERROR} text={error.errorDescription} className="width-100" />}
            </Section>
        </>
    );
});